import React from 'react';
// import useStyles from './style';
import PropTypes from 'prop-types';
import { decodeHTML } from '../../../utils/decodeHTML';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
// import { COMPONENT_NAME } from './locators';
import { isEmpty } from 'lodash';

const CookieConsent = props => {
  const { codeSnippet, scriptURL } = props.fields;
  // const classes = useStyles(props);

  const snippet =
    codeSnippet &&
    codeSnippet.value &&
    decodeHTML(codeSnippet.value) || '';
  // const injectScript = ({ url = '', textContent = '', innerHTML = '' }) => {
  const injectScript = ({ url = '', textContent = '' }) => {
    // check whether the code executes in a browser environment
    if (typeof window === "undefined" || typeof document === "undefined") return;

    if (isEmpty(url) && isEmpty(textContent)) return;

    textContent = textContent.replaceAll("\\r\\n", " ");  // replace \r\n with space 
    textContent = textContent.replaceAll("\\\"", "\"");  // replace \" with "
    textContent = textContent.replaceAll("\\\\", "\\");  // replace \\ with \

    let parser = new DOMParser();
    let doc = parser.parseFromString(textContent, "text/html");

    let scripts = doc.getElementsByTagName('script');

    for (let i = 0; i < scripts.length; i++) {
      let originalScript = scripts[i];
      let newScript = document.createElement('script');

      newScript.text = originalScript.innerText;

      for (let j = 0; j < originalScript.attributes.length; j++) {
        let attr = originalScript.attributes[j];
        newScript.setAttribute(attr.name, attr.value);
      }

      document.body.appendChild(newScript);
    }
  }

  return snippet && scriptURL && scriptURL.value ? (
    <>
      {injectScript({ textContent: snippet })}
      {injectScript({ url: scriptURL && scriptURL.value || '' })}
    </>
  ) : <></>;
};

CookieConsent.propTypes = {
  fields: PropTypes.shape({
    codeSnippet: PropTypes.object,
    scriptURL: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default withErrorBoundary(CookieConsent);

